import React, { useState } from "react"
import { navigate } from "gatsby"
import { Modal, Button } from "react-bootstrap"
import HubspotForm from "react-hubspot-form"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Helmet } from "react-helmet"
import useLiveControl from "./../controlled/useLiveControl"
import Layout from "../components/layout"
import Seo from "../components/seo"
import Video from "../components/video"

const Feedback = () => {
  const [show, setShow] = useState(false)

  const message = false
  // const { register, handleSubmit, errors } = useForm();
  const handleClose = () => setShow(false)

  const customLink = (url, e) => {
    navigate(url)
  }  

  const { isStaging } = useLiveControl()

  return (
    <Layout>
      <Seo
        title={"Get Started"}
        description={"America's home improvement lender of choice"}
      />
      <Helmet>
        <body className="lead-page" />
      </Helmet>
      <section id="join-today" className="lead-form">    
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <h1 className="pb-3">Like what you see?</h1>
              <p className="pb-3">
                Learn more about how our choice of payment options can grow
                average project size, boost close rate, and increase leads.
                Submit the form and we&#39;ll get in touch as soon as we can.
              </p>
              <div className="embed-container">
                <Video
                  videoSrcURL={
                    "https://player.vimeo.com/video/711390789?h=a7e0caf4de&amp"
                  }
                  allow="autoplay"
                  videoTitle="Increase your leads and sales by offering EnerBank financing options for all of your home improvement jobs."
                  videoDescription="EnerBank's contractor financing can help you grow your home improvement business."
                  videoWidth="100%"
                  videoHeight="500"
                />
              </div>
            </div>
            <div className="col-md-6">
              <div>
                <HubspotForm
                  portalId="381510"
                  formId="97ff0915-f670-4588-ad0b-1ae644a652b1"
                  onSubmit={() => this.handleFormSubmit}
                  onReady={form => console.log("Form ready!")}
                  loading={<div>Loading...</div>}
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="rds-marquee marquee-green pb-3">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-12">
              <h2 className="text-white text-center pb-5 mb-3">
                Ready to work with a lender that places you first?
              </h2>
            </div>
            <div className="col-md-6">
              <p className="text-white pb-5">
                Our dedicated relationship managers are intimately familiar with
                your accounts and serve as a single point of contact empowered
                to act on each contractor&#39;s behalf, including tailoring
                products to their specifications. Your customers will benefit
                from our professional, US based customer service team.{" "}
              </p>
              <p>
                <button
                  className="btn btn-gray"
                  onClick={() =>
                    customLink("#join-today")}
                >
                  Join Today!
                </button></p>
            </div>
            <div className="col-md-6">
              <div className="list-box card mb-0">
                <h3>
                  There Are a Few Criteria For You to Qualify to Offer Regions
                  Payment Options, Including:
                </h3>
                <ul className="fa-ul eb-green">
                  <li>
                    <i className="fa-li">
                      <FontAwesomeIcon
                        className="fa-icon"
                        icon="check"
                      />
                    </i>
                    A residential home remodeling focus for your business
                  </li>
                  <li>
                    <i className="fa-li">
                      <FontAwesomeIcon
                        className="fa-icon"
                        icon="check"
                      />
                    </i>
                    A U.S.-based business that has been in operation for at
                    least three years
                  </li>
                  <li>
                    <i className="fa-li">
                      <FontAwesomeIcon
                        className="fa-icon"
                        icon="check"
                      />
                    </i>
                    An average project size of at least $5,000
                  </li>
                  <li>
                    <i className="fa-li">
                      <FontAwesomeIcon
                        className="fa-icon"
                        icon="check"
                      />
                    </i>
                    Annual residential remodeling sales of at least $500,000
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Modal show={show} onHide={handleClose}>
        <Modal.Body
          className="p-4 mt-5 text-center"
          dangerouslySetInnerHTML={{ __html: message }}
        />
        <Modal.Footer className="border-0">
          <Button variant="primary mb-3 mx-auto" onClick={handleClose}>
            OK
          </Button>
        </Modal.Footer>
      </Modal>
    </Layout>
  )
}

export default Feedback

// export const query = graphql`
//     query($databaseId: ID!) {
//         wpgraphql {
//             page(id: $databaseId, idType: DATABASE_ID) {
//                 title
//                 date
//                 content(format: RENDERED)
//                 featuredImage {
//                     altText
//                     title(format: RENDERED)
//                     mediaItemUrl
//                     slug
//                 }
//             }
//         }
//     }
// `
